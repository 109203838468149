import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Table from '../components/Table'
import Signature from '../components/Signature'

export default function ECNFormTemplate({ data, pageContext }) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const productName = filePathParts[0]
  const documentName = filePathParts[2]
  return (
    <Layout title={documentName} filePath={filePath}>
      <div className="tile is-ancestor is-vertical">
        <div className="tile is-parent">
          <article className="tile is-child is-4">
            <p className="title">Project/Product Name</p>
            <p className="subtitle">{productName}</p>
          </article>
          <article className="tile is-child is-2">
            <p className="title">Origination Date</p>
            <p className="subtitle">{frontmatter.originationDate}</p>
          </article>
          <article className="tile is-child is-3">
            <p className="title">Originated By</p>
            <p className="subtitle">{frontmatter.originatedBy}</p>
          </article>
          <article className="tile is-child is-3">
            <p className="title">Affected Vendor</p>
            <p className="subtitle">{frontmatter.affectedVendor}</p>
          </article>
        </div>
        <div className="tile is-parent">
          <article className="tile is-child is-12">
            <p className="title">Change Details</p>
          </article>
        </div>
        <div className="tile is-parent">
          <article className="tile is-child is-2">
            <p className="subtitle">Reason for Change</p>
          </article>
          <article className="tile is-child is-10">
            <p className="subtitle">{frontmatter.reasonForChange}</p>
          </article>
        </div>
        <div className="tile is-parent">
          <article className="tile is-child is-2">
            <p className="subtitle">Description Of Change</p>
          </article>
          <article className="tile is-child is-10">
            <p className="subtitle">{frontmatter.descriptionOfChange}</p>
          </article>
        </div>
      </div>
      <Table table={frontmatter.affectedProductsTable} />
      <div className="tile is-parent">
        <article className="tile is-child is-12">
          <p className="title">Approval</p>
        </article>
      </div>
      <div className="tile is-parent">
        <article className="tile is-child is-12"></article>
      </div>
      <hr />
      <div className="tile is-parent">
        <Signature caption="Engineering" />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQueryECN($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      fields {
        filePath
      }
      frontmatter {
        originationDate
        originatedBy
        affectedVendor
        releaseLevel
        changeType
        reasonForChange
        descriptionOfChange

        affectedProductsTable {
          partNumber
          currentRevision
          futureRevision
          description
        }
      }
    }
  }
`
